/* Third Party -----------------------------------------------------*/

import $           from 'jquery';
import {TweenMax}  from "gsap";
import ScrollMagic from 'scrollmagic';
import 'ScrollMagic/scrollmagic/minified/plugins/animation.gsap.min.js';
import 'ScrollMagic/scrollmagic/minified/plugins/debug.addIndicators.min.js';
import 'gsap/src/minified/plugins/ScrollToPlugin.min.js';

const slick = require('slick-carousel');

/* Slider -----------------------------------------------------*/

let controller = new ScrollMagic.Controller();

hamburger('#hamburger');

function hamburger(hamburger) {
    let $nav               = $('.header__content__nav__list'), 
        $hamburger         = $(hamburger),
        $lineOne           = $hamburger.find('span:first-child'),
        $lineTwo           = $hamburger.find('span:nth-child(2)'),
        $lineThree         = $hamburger.find('span:last-child'),
        hamburgerAnimation = new TimelineMax({ paused: true }),
        canClick           = 1;

    hamburgerAnimation
        .to($lineOne, 0.3, { top: "50%" }, 'start')
        .to($lineThree, 0.3, { top: "50%" }, 'start')
        .set($lineTwo, { autoAlpha: 1 }, 'start')
        .set($lineTwo, { autoAlpha: 0 }, 'mid')
        .to($lineOne, 0.3, { rotation: 45 }, 'end')
        .to($lineThree, 0.3, { rotation: -45 }, 'end');

    $hamburger.on('click', function () {
        if (canClick) {
            canClick = 0;
            if (!$hamburger.hasClass('open')) {
                hamburgerAnimation.restart();
                $hamburger.addClass('open');
                $nav.slideDown()
            } else {
                hamburgerAnimation.reverse();
                $hamburger.removeClass('open');
                $nav.slideUp()
            }
            setTimeout(function () {
                canClick = 1;
            }, 500);
        }
    })
};

$('.js-slider-block').each(function () {
    let $this    = $(this),
        $slider  = $this.find('.js-slider'),
        $nextBtn = $this.find('.js-slider-next'),
        $prevBtn = $this.find('.js-slider-prev'),
        fade     = false,
        autoPlay = false,
        dots     = false;

    if ($slider.is('.js-sliderFade')) {
        fade     = true;
    }

    if ($slider.is('.js-sliderAuto')) {
        autoPlay = true;
    }

    if ($slider.is('.js-sliderDots')) {
        dots = true;
    }

    // works using class names `bp-up-i` so `sm-up-4` outputs 4 slides to be shown on small screens upwards
    let breakpoints           = { init: 0, xxs: 480, xs: 540, sm: 640, md: 768, xmd: 968, lg: 1024, xl: 1280, xxl: 1440 };
    let responsiveBreakpoints = [];
    let classBreakpoints      = $slider[0].className.split(' ');

    classBreakpoints.forEach(function (value) {
        if (value.indexOf(`-up-`) > -1) {
            let breakpoint = value.split('-')[0];
            let slides     = parseInt(value.split('-').pop().trim());
            responsiveBreakpoints.push({ breakpoint: breakpoints[breakpoint], settings: { slidesToShow: slides, slidesToScroll: slides } });
        }
    });

    $slider.slick({
        dots         : dots,
        arrows       : false,
        infinite     : true,
        mobileFirst  : true,
        fade         : fade,
        speed        : 300,
        autoplay     : autoPlay,
        autoplaySpeed: 5000,
        pauseOnHover : false,
        responsive   : responsiveBreakpoints
    });

    if ($slider && $nextBtn) {
        $nextBtn.on('click', function () {
            $slider.slick("slickNext");
        })
    }
    if ($slider && $prevBtn) {
        $prevBtn.on('click', function () {
            $slider.slick("slickPrev");
        })
    }
});

$('.js-staggerIn').each(function () {
    let $this     = $(this),
        $target   = $this.find('.js-staggerIn-watch:not(.slick-cloned)'),
        staggerIn = new TimelineMax();

    staggerIn.staggerFrom($target, 0.7, { autoAlpha: 0, yPercent: 10 }, 0.2, 0.2);

    let staggerInScene = new ScrollMagic.Scene({ triggerElement: this, triggerHook: 1, reverse: false })
        .setTween(staggerIn)
        // .addIndicators('tween', 'element')
        .addTo(controller);
})

function fadeUp(element) {

    $(element).each(function () {
        let $element = $(this),
            fadeUp   = new TimelineMax({});

        fadeUp.to($element, 1.5, { ease: Power2.easeInOut, autoAlpha: 1, 'top': 0 });

        let fadeUpScene = new ScrollMagic.Scene({ triggerElement: this, triggerHook: 1, reverse: false })
            .setTween(fadeUp)
            // .addIndicators('tween', 'element')
            .addTo(controller);
    })
}

fadeUp('[data-fade]');

function parallax(trigger) {
    $(trigger).each(function () {
        let $trigger = $(this),
            $target  = $($trigger.data('parallax')),
            hook     = $trigger.data('hook'),
            parallax = new TimelineMax({});

        parallax.to($target, 1, { 'top': '0' });

        if (hook == undefined) {
            hook = 0;
        }

        let parallaxScene = new ScrollMagic.Scene({ triggerElement: this, triggerHook: hook, duration: "150%" })
            .setTween(parallax)
            // .addIndicators('tween', 'element')
            .addTo(controller);
    })
}

parallax('[data-parallax]');

function tabs(parent) {
    $(parent).each(function () {
        let $this = $(this),
            $tab  = $this.find('.js-tabs-link'),
            $item = $this.find('.js-tabs-item');

        $tab.on('click', function (e) {
            e.preventDefault();
            filterContent($this, $tab, $item, this);
        })
    })
}

tabs('.js-tabs');

function filterContent($parent, $tab, $item, link) {
    let cat = $(link).data('panel').toLowerCase(),
            $target;

        collapseAndRemove($parent.find('.ajax-response'));

        // Figure out what the category is
        if ($(link).data('panel') == 'All') {
            // Asign the target as all the items
            $target = $parent.find('.js-tabs-item');
        } else {
            // Assign the target to the relvant items
            $target = $parent.find(`[data-category="${cat}"]`);
        }

        // Clear active state on tab links
        $tab.removeClass('js-active');
        // Clear active state on tab items
        $item.removeClass('js-active');
        // Hide tab items
        $item.addClass('js-hide');
        // Add active state to this tab link
        $(link).addClass('js-active');
        // Unhide relevant tab items
        $target.removeClass('js-hide');
}


function collapseAndRemove(element) {
    $(element).slideUp(function () {
        $(this).remove();
    });
}

function accordionGrid(parent) {
    $(parent).each(function () {
        let $parent         = $(this),
            $items          = $parent.find('.js-accordion-grid-item'),
            initWindowWidth = $(window).width(),
            threshold       = 50,
            resizeTimer

        $items.on('click', function (e) {
            e.preventDefault();
            var currentUrl = window.location.href,
                newUrl = $(this).find('a').attr('href');

            window.history.replaceState({}, currentUrl, newUrl);
            ajaxStores($items, $(this));
        });
        $(window).on('resize', function (e) {
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(function () {
                if ($(window).width() <= (initWindowWidth - threshold) || $(window).width() >= (initWindowWidth + threshold)) {
                    initWindowWidth = $(window).width();
                    $items.removeClass('js-active').blur();
                    collapseAndRemove('.ajax-response');
                }
            }, 250);
        });

    })
}

function ajaxStores($items, $this) {
    let $target         = lastInRow($items, $this, $this.index()),
        templateUrl     = $this.data('url'),
        initWindowWidth = $(window).width();

    if (!$this.hasClass('js-active')) {

        $items.removeClass('js-active');
        $this.addClass('js-active');
        $.ajax({
            url    : templateUrl,
            success: function (response) {
                collapseAndRemove('.ajax-response');
                $target.after(response);
                $target.next().slideDown();
            }
        });
    } else {
        $items.removeClass('js-active');
        collapseAndRemove('.ajax-response');
    }
}

var currentUrl = window.location.origin + window.location.pathname,
    $currentStore = $(`[data-url="${currentUrl}"]`),
    $currentPanel = $(`[data-panel-url="${currentUrl}"]`);


if ($currentStore.length) {
    ajaxStores($('.js-accordion-grid-item'), $currentStore);
    $('[data-panel="All"]').addClass('js-active');
    scrollTo($currentStore, 0);
}
if ($currentPanel.length) {
    $('[data-panel-url]').removeClass('js-active');
    $currentPanel.addClass('js-active');
    filterContent($('.js-tabs'), $('.js-tabs-link'), $('.js-tabs-item'), `[data-panel-url="${currentUrl}"]`);
    if ($currentPanel.data('panel') != "All") {
        scrollTo('.currentStores', 100);
    }
}

accordionGrid('.js-accordion-grid');




$('body').on('click', '.js-accordion-grid .js-close', function () {
    $('.js-accordion-grid-item ').removeClass('js-active');
    // collapseAndRemove('.ajax-response');
    $(this).parents(`.${$(this).data('remove')}`).slideUp(400, function () {
        $(this).remove();
    });
})

function toModal(trigger) {
    $(trigger).each(function () {
        let $this = $(this);
        let thisStore = $this.index('.available');


        $this.on('click', function () {
            $('.js-modalBlock').fadeIn(200, function () {
                modalClose('.js-modalBlock');
            })
            $('.js-modalBlock .js-slider').slick('slickGoTo', thisStore);
            $(window).trigger('resize');
        })
    })
}

toModal('.js-toModal');

function modalClose(element) {
    $(element).each(function () {
        let $this     = $(this),
            $closeBtn = $this.find('.js-close');

        $this.on('click', function (e) {
            if ($this.is(e.target)) {
                $this.fadeOut(200, function () {
                    // $this.remove();
                })
            }
        })
        $closeBtn.on('click', function () {
            $this.fadeOut(200, function () {
                // $this.remove();
            })
        })
    })
}

modalClose('.js-modalBlock');

$.ajax({
    url     : `https://maps.googleapis.com/maps/api/js?key=${$('.js-map').data('api-key')}`,
    dataType: "script",
    success : googleMap
});

function googleMap() {
    $('.js-map').each(function () {

        let MY_MAPTYPE_ID = 'custom_style';

        let featureOpts = [
            {
                stylers: [
                    { hue: $(this).data('map-color') },
                    { visibility: 'simplified' },
                    { gamma: 0.5 },
                    { weight: 0.5 }
                ]
            },
            {
                featureType: 'water',
                stylers: [
                    { color: $(this).data('water-color') }
                ]
            }
        ];

        let styledMapOptions = {
            name: 'Stylised'
        };

        let customMapType = new google.maps.StyledMapType(featureOpts, styledMapOptions);

        let lngLat  = { lng: $(this).data('lng'), lat: $(this).data('lat') },
            // lat = $(this).data('lat'),
            thisMap = new google.maps.Map(this, {
                center          : lngLat,
                zoom            : $(this).data('zoom'),
                disableDefaultUI: false,
                mapTypeControlOptions: {
                    mapTypeIds: [google.maps.MapTypeId.ROADMAP, MY_MAPTYPE_ID]
                },
                mapTypeId: MY_MAPTYPE_ID
            });

        thisMap.mapTypes.set(MY_MAPTYPE_ID, customMapType);

        let marker = new google.maps.Marker({
            position: lngLat,
            map     : thisMap
            // title: 'Hello World!'
        });
    })
}

function scrollTo(target, offset){
    var scrollTo = $(target).offset().top - offset;

    TweenLite.to(window, 1.5, {scrollTo: {y:scrollTo, autoKill:false}, ease:Power1.easeInOut});
}

function lastInRow($items, $el, index) {
    let offsetTop = $el.offset().top;
    let item      = $el;
    $items.slice(index).each(function () {
        if ($(this).offset().top > offsetTop) {
            return false;
        } else {
            item = $(this);
        }
    });
    return item;
}
